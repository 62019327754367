import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { DrawerV2 } from 'site-modules/shared/components/drawer/drawer-v2';
import { GlobalSearchDialogContent } from 'site-modules/shared/components/inventory/global-search/global-search-dialog-content/global-search-dialog-content';

const drawerClassNames = { drawerContainer: 'h-100 d-flex', drawerContent: 'w-100' };

export const GlobalSearchDrawer = memo(
  ({
    id,
    ariaLabel,
    searchId,
    creativeId,
    query,
    fastMatcherData,
    onInputChange,
    onQueryCancel,
    onSearchSubmit,
    onDrawerClose,
    loadingComponent,
    isFastMatcherLoading,
    isOpen,
    isLoading,
    isError,
    withLlmSearchDisabled,
    isMobile,
    trapFocus,
    withEnterSubmit,
  }) => (
    <DrawerV2
      isOpen={isOpen}
      toggle={noop}
      side="bottom"
      size="small"
      id={id}
      ariaLabel={ariaLabel}
      noInitialFocus
      classNames={drawerClassNames}
      unmountOnExit
      trapFocus={trapFocus}
    >
      <GlobalSearchDialogContent
        onDrawerClose={onDrawerClose}
        isMobile={isMobile}
        fastMatcherData={fastMatcherData}
        isFastMatcherLoading={isFastMatcherLoading}
        query={query}
        isLoading={isLoading}
        isError={isError}
        loadingComponent={loadingComponent}
        searchId={searchId}
        withLlmSearchDisabled={withLlmSearchDisabled}
        onInputChange={onInputChange}
        onQueryCancel={onQueryCancel}
        onSearchSubmit={onSearchSubmit}
        creativeId={creativeId}
        withEnterSubmit={withEnterSubmit}
      />
    </DrawerV2>
  )
);

GlobalSearchDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  searchId: PropTypes.string,
  creativeId: PropTypes.string,
  query: PropTypes.string,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  loadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onSearchSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  onQueryCancel: PropTypes.func,
  onDrawerClose: PropTypes.func,
  isFastMatcherLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  trapFocus: PropTypes.bool,
  withEnterSubmit: PropTypes.bool,
};

GlobalSearchDrawer.defaultProps = {
  ariaLabel: '',
  searchId: '',
  creativeId: '',
  query: '',
  fastMatcherData: null,
  loadingComponent: undefined,
  onSearchSubmit: noop,
  onInputChange: noop,
  onQueryCancel: noop,
  onDrawerClose: noop,
  isFastMatcherLoading: false,
  isLoading: false,
  isError: false,
  isOpen: false,
  withLlmSearchDisabled: false,
  isMobile: false,
  trapFocus: undefined,
  withEnterSubmit: false,
};
