import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { PAGE_NAMES } from 'site-modules/shared/constants/multi-offer/offers';
import { CBP_LANDING, TOP_RATED } from 'site-modules/shared/constants';
import { PRIVATE_PARTY_PAGE_NAMES } from 'site-modules/shared/constants/private-party/page-names';
import { JoinInsider } from 'site-modules/shared/components/profile/join-insider/join-insider-sign-up';
import { AppDownload } from 'site-modules/shared/components/app-download/app-download';

import { LINKS, LINKS_W_FOR_DEALERS, FOR_DEALERS_LINK, SOCIAL_LINKS_GLOBAL_FOOTER } from './footer-data';
import './footer.scss';

const REMOVE_NO_FOLLOW_BY_PAGE_NAME = ['inventory_comparator'];
const excludesJoinInsiderByPageName = pageName =>
  ![
    CBP_LANDING,
    PAGE_NAMES.OFFER_RESULTS_NOT_FOUND,
    PAGE_NAMES.MULTI_OFFER_RESULTS,
    PAGE_NAMES.OFFER_DETAILS_PAGE,
    PAGE_NAMES.APPRAISER_OFFER_RENEWAL,
    PRIVATE_PARTY_PAGE_NAMES.PRIVATE_PARTY_CREATE_LISTING,
    PRIVATE_PARTY_PAGE_NAMES.PRIVATE_PARTY_CREATE_LISTING_VEHICLE_DETAILS,
    PRIVATE_PARTY_PAGE_NAMES.PRIVATE_PARTY_CREATE_LISTING_PRICE,
    PRIVATE_PARTY_PAGE_NAMES.PRIVATE_PARTY_CREATE_LISTING_PHOTOS,
    PRIVATE_PARTY_PAGE_NAMES.PRIVATE_PARTY_CREATE_LISTING_SELLER,
    PRIVATE_PARTY_PAGE_NAMES.PRIVATE_PARTY_CREATE_LISTING_PREVIEW,
  ].includes(pageName);
const CCPA_URL = '/ccpa/';

function renderFooterLinks(removeNoFollow, includeForDealers) {
  const footerLinks = includeForDealers ? LINKS_W_FOR_DEALERS : LINKS;

  return footerLinks.map(({ href, text }) => {
    const forceNoFollow = CCPA_URL === href || FOR_DEALERS_LINK.href === href;

    return (
      <li
        className="global-footer-link small mb-1 mb-md-0"
        key={text}
        style={{ marginRight: '28px', lineHeight: '24px' }}
      >
        <a href={href} rel={removeNoFollow && !forceNoFollow ? undefined : 'nofollow'}>
          {text}
        </a>
      </li>
    );
  });
}

export function renderSocialLinks() {
  return SOCIAL_LINKS_GLOBAL_FOOTER.map(({ href, title, icon }) => (
    <li key={title} className="me-1_5">
      <a href={href} title={title} aria-label={title} className="d-inline-block">
        <span className={`${icon} social-link-icon`} aria-hidden />
      </a>
    </li>
  ));
}

function FooterUI({ isHomepage, pageName, isMobile }) {
  const shouldShowInsider = excludesJoinInsiderByPageName(pageName) && (pageName && !pageName.includes(TOP_RATED));
  const removeNoFollow = isHomepage || REMOVE_NO_FOLLOW_BY_PAGE_NAME.includes(pageName);

  return (
    <div className="global-footer text-gray-darker pos-r" data-tracking-parent="edm-entry-global-footer">
      <div className="pos-a w-100 h-100 footer-bg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="pointer-events-none hidden-md-up pos-a h-100 w-100"
          preserveAspectRatio="none"
          viewBox="0 0 375 826"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M0 1013.66h375V0a2111.167 2111.167 0 0 1-17.656 4.943C234.694 35.694 113.879 54.29 0 59.513v954.147Z"
            clipRule="evenodd"
          />
          <path
            fill="url(#lin-grad-sm)"
            fillOpacity=".7"
            fillRule="evenodd"
            d="M0 1013.66h375V0a2111.167 2111.167 0 0 1-17.656 4.943C234.694 35.694 113.879 54.29 0 59.513v954.147Z"
            clipRule="evenodd"
          />
          <defs>
            <linearGradient
              id="lin-grad-sm"
              x1={40.781}
              x2={398.923}
              y1={464.504}
              y2={471.09}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F0F6FF" />
              <stop offset=".495" stopColor="#F0FDFF" />
              <stop offset={1} stopColor="#F8F0FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="pointer-events-none hidden-sm-down pos-a h-100 w-100"
          preserveAspectRatio="none"
          viewBox="0 0 1200 487"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M-200 67.763c362.569 66.46 778.038 68.651 1209.44 11.08C1143.06 57.825 1273.61 31.724 1400 .72V632H-200V67.763Z"
            clipRule="evenodd"
          />
          <path
            fill="url(#lin-grad-lg)"
            fillOpacity=".4"
            fillRule="evenodd"
            d="M-200 67.763c362.569 66.46 778.038 68.651 1209.44 11.08C1143.06 57.825 1273.61 31.724 1400 .72V632H-200V67.763Z"
            clipRule="evenodd"
          />
          <defs>
            <linearGradient
              id="lin-grad-lg"
              x1={-337}
              x2={652.995}
              y1={-35.5}
              y2={1121.18}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C2F8FF" />
              <stop offset=".566" stopColor="#F0F6FF" />
              <stop offset={1} stopColor="#FFF0F1" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {shouldShowInsider && <JoinInsider className="pb-2" />}
      <div className={classnames('footer-main container-fluid bg-faded pt-2 pb-3')}>
        <footer className="container">
          <div className="pb-2 overflow-hidden">
            <div className="float-md-start">
              <h2 className="mb-0_75 heading-5 fw-medium">Follow Us</h2>
              <ul className="social-links list-unstyled mb-2 mb-md-0 d-flex is-ads-10216-chal">
                {renderSocialLinks()}
              </ul>
            </div>
            <div className="float-md-end">
              <AppDownload />
            </div>
          </div>
          <ul className="global-links mb-2 mb-md-0_75 row flex-column flex-md-row flex-wrap list-unstyled px-0_5 pb-0_25 pb-md-0">
            {renderFooterLinks(removeNoFollow, isHomepage, true)}
          </ul>

          <div className="copyright small text-gray-darker" style={{ marginBottom: isMobile ? '40px' : '100px' }}>
            <a href="/">&copy; Edmunds.com, Inc.</a>, a wholly owned subsidiary of CarMax, Inc. By using edmunds.com,
            you consent to the monitoring and storing of your interactions with the website, including by an Edmunds
            vendor, for use in improving and personalizing our services. See our{' '}
            <a href="/about/privacy.html">Privacy Statement</a> for details.
          </div>
        </footer>
      </div>
    </div>
  );
}

FooterUI.propTypes = {
  isHomepage: PropTypes.bool,
  isMobile: PropTypes.bool,
  pageName: PropTypes.string,
};

FooterUI.defaultProps = {
  isHomepage: false,
  isMobile: false,
  pageName: null,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  pageName: get(state, 'pageContext.page.name'),
});

export const Footer = connect(mapStateToProps)(FooterUI);
