import React, { useCallback, useDeferredValue, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
/* Constants */
import { OPEN_ANIMATION } from 'site-modules/shared/components/drawer/drawer';
import {
  CLEAR_INPUT,
  AUTOSIZED_AREA_LENGTH,
  LOOKING_FOR_PLACEHOLDER,
} from 'site-modules/shared/constants/global-search/global-search';
import { KEY_CODES } from 'site-modules/shared/components/home-vehicle-search-autocomplete/home-vehicle-search-autocomplete';
/* Utils */
import { fireTemporaryKeydownTracking } from 'site-modules/shared/utils/inventory/global-search-tracking';
/* Hooks */
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
/* Components */
import { GlobalSearchResults } from 'site-modules/shared/components/inventory/global-search/global-search-results/global-search-results';

import './global-search-dialog-content.scss';

export function GlobalSearchDialogContent({
  searchId,
  creativeId,
  fastMatcherData,
  query,
  onSearchSubmit,
  onInputChange,
  onQueryCancel,
  onDrawerClose,
  loadingComponent,
  isFastMatcherLoading,
  isLoading,
  isError,
  withLlmSearchDisabled,
  withEnterSubmit,
}) {
  const deferredQuery = useDeferredValue(query);
  const [isSelectOptionMessageShown, setIsSelectOptionMessageShown] = useState(false);
  const inputRef = useRef();
  const [drawerAnimationTimeout, clearDrawerAnimationTimeout] = useTimeout();

  useEffect(() => {
    drawerAnimationTimeout(() => {
      inputRef.current?.focus();
    }, OPEN_ANIMATION);

    return () => {
      clearDrawerAnimationTimeout();
    };
  }, [clearDrawerAnimationTimeout, drawerAnimationTimeout]);

  const handleInputChange = useCallback(
    async ({ target: { value } }) => {
      await onInputChange(value);
    },
    [onInputChange]
  );

  const handleQueryCancel = useCallback(async () => {
    await onQueryCancel();
    inputRef.current?.focus();
  }, [onQueryCancel]);

  const handleKeyDown = useCallback(
    async event => {
      if (event.keyCode === KEY_CODES.escape) {
        onDrawerClose();
      }

      if (event.keyCode === KEY_CODES.enter) {
        event.preventDefault();

        if (withEnterSubmit) {
          onSearchSubmit(event);
        } else {
          setIsSelectOptionMessageShown(true);
        }
      }

      fireTemporaryKeydownTracking({ event, creativeId });
    },
    [creativeId, onDrawerClose, onSearchSubmit, withEnterSubmit]
  );

  return (
    <div className="global-search-dialog-content bg-white p-1">
      <Form
        name="global-search-dialog-form"
        onSubmit={onSearchSubmit}
        onKeyDown={handleKeyDown}
        data-tracking-parent={creativeId}
        noValidate
      >
        <div className="d-flex align-items-center mb-1">
          <Button onClick={onDrawerClose} className="p-0 me-1 border-0 background-none" aria-label="Cancel search">
            <i className="text-cool-gray-50 icon-arrow-left3" aria-hidden />
          </Button>
          <div className="global-search-input pos-r w-100">
            <Input
              type="text"
              id="global-search-input"
              name="global-search-input"
              autoComplete="off"
              placeholder={LOOKING_FOR_PLACEHOLDER}
              className={classnames('search-field h-100 size-16 bg-cool-gray-80 rounded-12 border-0', {
                'pt-1_75 ps-0_75 pe-2_5 with-text': !!query,
                'py-0_5 px-0_75': !query,
              })}
              value={query}
              onChange={handleInputChange}
              maxLength={AUTOSIZED_AREA_LENGTH}
              innerRef={inputRef}
            />
            <label className="search-label" htmlFor="global-search-input">
              {LOOKING_FOR_PLACEHOLDER}
            </label>
            {!!query && (
              <Button
                onClick={handleQueryCancel}
                className="query-cancel-btn pos-a p-0 border-0 background-none d-flex justify-content-center align-items-center"
              >
                <i className="text-cool-gray-50 icon-cross2 small" role="img" aria-label={CLEAR_INPUT} />
              </Button>
            )}
          </div>
        </div>
        <GlobalSearchResults
          searchQuery={deferredQuery}
          isError={isError}
          fastMatcherData={fastMatcherData}
          isFastMatcherLoading={isFastMatcherLoading}
          searchId={searchId}
          isMobile
          isSelectOptionMessageShown={isSelectOptionMessageShown}
          withLlmSearchDisabled={withLlmSearchDisabled}
          creativeId={creativeId}
        />
      </Form>
      {isLoading && loadingComponent}
    </div>
  );
}

GlobalSearchDialogContent.propTypes = {
  searchId: PropTypes.string,
  creativeId: PropTypes.string,
  query: PropTypes.string,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  loadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onSearchSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  onQueryCancel: PropTypes.func,
  onDrawerClose: PropTypes.func,
  isFastMatcherLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
  withEnterSubmit: PropTypes.bool,
};

GlobalSearchDialogContent.defaultProps = {
  searchId: '',
  creativeId: '',
  query: '',
  loadingComponent: undefined,
  fastMatcherData: null,
  isFastMatcherLoading: false,
  onSearchSubmit: noop,
  onInputChange: noop,
  onQueryCancel: noop,
  onDrawerClose: noop,
  isLoading: false,
  isError: false,
  withLlmSearchDisabled: false,
  withEnterSubmit: false,
};
