import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { getPlaceholderText } from 'site-modules/shared/components/home-vehicle-search/search-box-placeholder';
import { HomeVehicleSearch } from 'site-modules/shared/components/home-vehicle-search/home-vehicle-search';
import { GlobalNavigationSearch } from 'site-modules/shared/components/inventory/global-search/global-navigation-search/global-navigation-search';

import './search-box.scss';

const ADDON_CLASSES = {
  opened: 'search-box-icon icon-cross3 text-info px-0_75 py-1 bg-white border-0 p-md-0',
  closed: 'search-box-icon icon-search px-0_75 py-1 bg-white border-0 p-md-0',
  submitted: 'search-box-icon text-info bg-white border-0 d-flex justify-content-center align-items-center',
};

const CREATIVE_ID = 'global-nav-autocomplete';

function SearchBoxUI({
  className,
  closeClassName,
  onDropDownOpen,
  onDropDownClose,
  onCrossClick,
  segment,
  closeStyle,
  pathname,
  isMobile,
}) {
  const placeholder = getPlaceholderText(segment);

  return (
    <FeatureFlag name="disable-homepage-llm">
      {disableLlm => (
        <Experiment name="search-42-global-nav" showDefault>
          <Recipe name="ctrl" isDefault>
            <HomeVehicleSearch
              trackingCreativeId={CREATIVE_ID}
              wrapperClasses={classnames('autocomplete-search', className)}
              closeStyle={closeStyle}
              closeClassName={closeClassName}
              searchBoxClasses="autocomplete-search-box w-100"
              searchInputClasses="ps-0_25 pe-1 py-0_5"
              addonClasses={ADDON_CLASSES}
              placeholder={placeholder}
              onDropDownOpen={onDropDownOpen}
              onDropDownClose={onDropDownClose}
              onCrossClick={onCrossClick}
              pathname={pathname}
              isMobile={isMobile}
            />
          </Recipe>
          <Recipe name="chal1">
            <GlobalNavigationSearch isMobile={isMobile} withLlmSearchDisabled={disableLlm} />
          </Recipe>
          <Recipe name="chal2">
            <GlobalNavigationSearch isMobile={isMobile} withLlmSearchDisabled={disableLlm} isSearch42Chal2 />
          </Recipe>
        </Experiment>
      )}
    </FeatureFlag>
  );
}

SearchBoxUI.propTypes = {
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  onDropDownOpen: PropTypes.func,
  onDropDownClose: PropTypes.func,
  onCrossClick: PropTypes.func,
  segment: PropTypes.shape({}),
  closeStyle: PropTypes.shape({}),
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
};

SearchBoxUI.defaultProps = {
  className: '',
  closeClassName: '',
  onDropDownOpen: null,
  onDropDownClose: null,
  onCrossClick: null,
  segment: null,
  pathname: '',
  closeStyle: null,
  isMobile: false,
};

const stateToPropsConfig = {
  segment: bindToPath('segment', VisitorModel, null, false),
};

export const SearchBox = connectToModel(React.memo(SearchBoxUI), stateToPropsConfig);
