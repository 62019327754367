import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Container from 'reactstrap/lib/Container';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { getIsAppraisalLinkExcluded } from 'site-modules/shared/utils/appraisal/check-links-visible';

import { GlobalNavigationMobileWrapper } from './global-navigation-mobile-wrapper';
import { GlobalNavigationMobile } from './global-navigation-mobile';
import { GlobalNavigationDesktop } from './global-navigation-desktop';

import './global-navigation.scss';

function GlobalNavigationUI({ isMobile, showSearchBar, pathname, pageName, selectedFacets, isSearch42Chal }) {
  const isAppraisalLinkExcluded = getIsAppraisalLinkExcluded(pageName, selectedFacets);

  return (
    <div className="global-navigation w-100" data-tracking-parent="edm-entry-global-nav">
      <Container className="px-0">
        {isMobile ? (
          <GlobalNavigationMobileWrapper>
            <GlobalNavigationMobile
              showSearchBar={showSearchBar}
              pathname={pathname}
              isAppraisalLinkExcluded={isAppraisalLinkExcluded}
            />
          </GlobalNavigationMobileWrapper>
        ) : (
          <GlobalNavigationDesktop
            showSearchBar={showSearchBar}
            pathname={pathname}
            isAppraisalLinkExcluded={isAppraisalLinkExcluded}
            isSearch42Chal={isSearch42Chal}
          />
        )}
      </Container>
    </div>
  );
}

GlobalNavigationUI.propTypes = {
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  pageName: PropTypes.string,
  selectedFacets: PropTypes.shape({}),
  isSearch42Chal: PropTypes.bool,
};

GlobalNavigationUI.defaultProps = {
  pathname: '',
  isMobile: false,
  showSearchBar: true,
  pageName: null,
  selectedFacets: {},
  isSearch42Chal: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  pathname: get(state, 'pageContext.location.pathname'),
  pageName: get(state, 'pageContext.page.name'),
  selectedFacets: get(state, 'inventory.searchResultsFilter'),
  isSearch42Chal: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'search-42-global-nav',
    defaultVal: 'ctrl',
  }).includes('chal'),
});

export const GlobalNavigation = connect(mapStateToProps)(GlobalNavigationUI);
