import React, { useCallback, useDeferredValue, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop, flow } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { useLlmResults } from 'site-modules/shared/hooks/global-search/use-llm-results';
import { GlobalSearch } from 'site-modules/shared/components/inventory/global-search/global-search';
import { ApiMetrics, withMetrics } from 'client/utils/metrics-hoc';
import { InventoryEntities } from 'client/data/models/inventory';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
import { transformSuggestedFilters } from 'site-modules/shared/utils/inventory/semantic-search';

const CREATIVE_ID = 'edm-entry-global-nav-semantic-search';

export function GlobalNavigationQueryUI({
  isMobile,
  setModelValue,
  suggestedFilters,
  searchId,
  apiMetrics,
  withLlmSearchDisabled,
  onSetOpen,
  isOpen,
  isSearchVisible,
}) {
  const [query, setQuery] = useState('');
  const deferredQuery = useDeferredValue(query);
  const trimmedQuery = deferredQuery?.trim();

  const { triggerSubmit, triggerChange, isLoading, isError, loadingComponent } = useLlmResults({
    suggestedFilters,
    searchId,
    setModelValue,
    query: trimmedQuery,
  });

  const onSearchChange = useCallback(
    async value => {
      setQuery(value);

      await triggerChange();
    },
    [triggerChange]
  );

  const closeGlobalSearch = useCallback(() => {
    onSetOpen(false);
    onSearchChange('');
  }, [onSearchChange, onSetOpen]);

  useEffect(() => {
    EventToolbox.on(PAGE_EVENTS.PAGE_LOAD, closeGlobalSearch);

    return () => {
      EventToolbox.off(PAGE_EVENTS.PAGE_LOAD, closeGlobalSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalSearch
      creativeId={CREATIVE_ID}
      query={query}
      onSearchSubmit={triggerSubmit}
      onSearchChange={onSearchChange}
      loadingComponent={loadingComponent}
      apiMetrics={apiMetrics}
      isMobile={isMobile}
      isLoading={isLoading}
      isError={isError}
      withLlmSearchDisabled={withLlmSearchDisabled}
      controlled
      isOpen={isOpen}
      onChangeOpenState={onSetOpen}
      isGlobalNav
      isSearchVisible={isSearchVisible}
    />
  );
}

GlobalNavigationQueryUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  apiMetrics: ApiMetrics.isRequired,
  searchId: PropTypes.string,
  suggestedFilters: PropTypes.shape({
    filters: PropTypes.arrayOf(InventoryEntities.FacetValue),
    inventoryCount: PropTypes.number,
    modifiedRadius: PropTypes.number,
    identifiedVin: PropTypes.shape({
      vin: PropTypes.string,
      vinInfo: InventoryEntities.InventoryVin,
      styleInfo: PropTypes.arrayOf(
        PropTypes.shape({
          makeNiceId: PropTypes.string,
          modelNiceId: PropTypes.string,
          year: PropTypes.number,
          styleId: PropTypes.number,
        })
      ),
    }),
  }),
  isMobile: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
  onSetOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isSearchVisible: PropTypes.bool,
};

GlobalNavigationQueryUI.defaultProps = {
  searchId: '',
  suggestedFilters: {},
  isMobile: false,
  withLlmSearchDisabled: false,
  onSetOpen: noop,
  isOpen: false,
  isSearchVisible: false,
};

export const GlobalNavigationQuery = flow(
  withMetrics,
  component =>
    connectToModel(component, {
      suggestedFilters: bindToPath(
        ({ searchId }) => (searchId ? `suggestedFilters["${searchId}"]` : null),
        SemanticSearchModel,
        transformSuggestedFilters,
        false
      ),
    }),
  component =>
    connectToModel(component, {
      searchId: bindToPath(({ searchRoute }) => buildSearchIdPath(searchRoute), SemanticSearchModel),
    })
)(GlobalNavigationQueryUI);
