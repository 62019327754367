import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import Button from 'reactstrap/lib/Button';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';

import { Storage } from 'site-modules/shared/utils/storage';
import { injectScript } from 'client/utils/inject-script';
import {
  auth,
  authProvider,
  firebaseCredentials,
  fireFirebaseTracking,
  getCurrentUser,
  onReject,
} from 'site-modules/shared/components/profile/firebase-auth';
import { getSavableData, getSocialProfile } from 'site-modules/shared/components/profile/idm/idm-utils';
import {
  displayGoogleOneTap,
  successSocialAuthAction,
} from 'client/engagement-handlers/profile-engagement-handler/profile-engagement-handler';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { UserProfileModel } from 'client/data/models/profile/profile';
import { VisitorModel } from 'client/data/models/visitor';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { profileScreenMethods } from 'site-modules/shared/components/profile/profile-screen-methods';
import { insiderMethods } from 'site-modules/shared/components/profile/insider-methods';
import { connectedProfileAuth } from 'site-modules/shared/components/profile/profile-auth';
import { PriceDropAlerts } from 'site-modules/shared/components/profile/price-drop-alerts/price-drop-alerts';
import { PriceDropAlertsPopover } from 'site-modules/shared/components/profile/price-drop-alerts-popover/price-drop-alerts-popover';
import { handleInsiderMenuOpen } from 'client/engagement-handlers/global-navigation-engagement-handler/global-navigation-engagement-handler';
import { venomHistory } from 'client/utils/history/venom-history';
import { getSavedItemsAmount } from 'site-modules/shared/components/profile/utils/extract-helper';
import { INSIDER_PATHS } from 'site-modules/shared/constants/profile/dashboard-paths';
import { Link } from 'site-modules/shared/components/link/link';

import { logger } from 'client/utils/isomorphic-logger';
import './insider-section.scss';

const DROPDOWN_ITEM_CLASSES = 'insider-dropdown-item text-gray-darker px-1';
const CREATIVE_ID = 'google_one_tap';
const GOOGLE_ONE_TAP_SHOWN = 'google_one_tap_shown';
const ATTEMPT_DISPLAY_GOOGLE_AUTHENTICATE = 'attempt_display_google_authenticate';
// https://contextify.dev/blog/google-one-tap-login-firebase/
const GOOGLE_GSI_SRC = 'https://accounts.google.com/gsi/client';

const AUTHORIZATION_LINKS = {
  sign: {
    text: 'Register/Sign In',
    trackingId: 'global_nav_begin_sign_up',
    trackingValue: 'Sign',
  },
  signOut: {
    text: 'Sign Out',
    trackingId: 'global_nav_sign_out',
    trackingValue: 'Sign Out',
  },
  account: {
    href: INSIDER_PATHS.SETTINGS_PAGE,
    text: 'Account',
    trackingId: 'global_nav_click_account',
    trackingValue: 'account',
  },
};

const AUTHORIZED_LINKS = [
  {
    href: INSIDER_PATHS.INVENTORY_PAGE,
    text: 'Saved Inventory',
    trackingId: 'global_nav_click_saved_inventory',
    trackingValue: 'saved-inventory',
    countKey: 'inventory',
    includeAlerts: true,
  },
  {
    href: INSIDER_PATHS.SEARCH_PAGE,
    text: 'Saved Searches',
    trackingId: 'global_nav_click_saved_searches',
    trackingValue: 'saved-searches',
    countKey: 'search',
  },
  {
    href: INSIDER_PATHS.APPRAISAL_PAGE,
    text: 'My Trade-in Values',
    trackingId: 'global_nav_click_trade_in_values',
    trackingValue: 'my-appraisals',
    countKey: 'appraisal',
  },
  {
    href: INSIDER_PATHS.FAVORITE_MODELS,
    text: 'Favorite Models',
    trackingId: 'global_nav_click_fav_models',
    trackingValue: 'saved-models',
    countKey: 'favorite-models',
  },
];

const renderNavItem = (item, counts, onClick) => {
  const LinkComp = item.href ? Link : 'a';
  const linkProp = item.href ? 'to' : '';
  return (
    <NavItem className="py-0_25 py-md-0" key={item.text}>
      <LinkComp
        {...{ [linkProp]: item.href }}
        onClick={onClick}
        className="nav-link px-0 px-md-1"
        data-tracking-id={item.trackingId}
        data-tracking-value={item.trackingValue}
        rel="nofollow"
        target={item.target}
      >
        <span className="global-nav-link">{item.text}</span>
        {item.countKey && !!counts[item.countKey] && (
          <span className="text-white ps-0_25">({counts[item.countKey]})</span>
        )}
        {item.includeAlerts && <PriceDropAlerts className="pos-r" hideCount />}
      </LinkComp>
    </NavItem>
  );
};

function InsiderSectionUI({
  isAuthenticated,
  isAnonymousUser,
  isMobile,
  className,
  disableGoogleOneTap,
  isGlobalNavDesktop,
  idmData,
  updateLoginIdmData,
  toggleSignModal,
  signIn,
  signOut,
  signInAnonymousAndSaveProfile,
  disableGoogleOneTapINP,
  privacyAccepted,
  isCore6746Enabled,
  pageName,
}) {
  const sessionStorage = useRef(null);
  const [dropdownOpen, toggleDropdown] = useToggle(false);
  const counts = { ...getSavedItemsAmount(idmData) };

  function doSignOut() {
    signOut();
    venomHistory.push('/');
  }

  async function authenticatedSignUp() {
    try {
      toggleSignModal();
      await signInAnonymousAndSaveProfile(null, { signInAnonymouslyTracking: { creativeId: 'sign-up-anonymous' } });
    } catch (error) {
      logger('warn', 'vehicle is not saved to a storage properly', error);
    }
    return Promise.resolve();
  }

  useEffect(() => {
    // Fire event for tablet/desktop
    if (dropdownOpen) {
      handleInsiderMenuOpen(false); // isMobile = false
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!sessionStorage.current) {
      sessionStorage.current = new Storage('sessionStorage');
    }
    const googleOneTapAvailable =
      isAuthenticated !== null && (!isAuthenticated || (isAnonymousUser && !isEmpty(idmData))) && !disableGoogleOneTap;
    const googleOneTapSkip =
      (isCore6746Enabled && !privacyAccepted) ||
      (disableGoogleOneTapINP && pageName === 'car_reviews_index' && isMobile);

    const showGoogleOneTap = sessionStorage.current.get(GOOGLE_ONE_TAP_SHOWN);

    if (!showGoogleOneTap && googleOneTapAvailable && !googleOneTapSkip) {
      sessionStorage.current.set(GOOGLE_ONE_TAP_SHOWN, true);
      injectScript(GOOGLE_GSI_SRC)
        .then(() => {
          displayGoogleOneTap(ATTEMPT_DISPLAY_GOOGLE_AUTHENTICATE);
          const firebase = firebaseCredentials();
          window.google.accounts.id.initialize({
            client_id: firebase.googleId,
            callback: ({ credential }) =>
              getCurrentUser().then(usr => {
                const uid = usr?.uid;
                return auth()
                  .signInWithCredential(authProvider().GoogleAuthProvider.credential(credential))
                  .then(() => {
                    fireFirebaseTracking('SIGN_IN_SOCIAL_CREATIVE_ID');
                    successSocialAuthAction({
                      creative_id: CREATIVE_ID,
                      value: 'google',
                    });
                    return getSocialProfile(getSavableData(idmData)).then(data =>
                      updateLoginIdmData(data, uid, true).then(() => signIn(false))
                    );
                  })
                  .catch(error => {
                    onReject(error, CREATIVE_ID);
                  });
              }),
            auto_select: false,
          });
          window.google.accounts.id.prompt();
        })
        .catch(error => {
          onReject(error, CREATIVE_ID);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymousUser, isAuthenticated, privacyAccepted, isCore6746Enabled, pageName, idmData]);

  const renderUnauthorizedSection = isMobile ? (
    <NavItem>
      <div className="insider-dropdown-menu unauthorized">
        <h3 className="pe-1 text-white">Save cars &amp; get price drop alerts</h3>
        <Button
          className="sign-up-button mt-0_5 w-50 bg-white"
          color="outline-primary-b"
          onClick={authenticatedSignUp}
          data-tracking-id="global_nav_begin_sign_up"
        >
          Sign up
        </Button>
        <div className="mt-1 medium text-white">
          Already have an account?{' '}
          <Button
            color="link"
            className="text-white text-underline text-transform-none p-0 fw-normal"
            onClick={toggleSignModal}
            data-tracking-id="global_nav_begin_sign_in"
          >
            Log in
          </Button>
        </div>
      </div>
    </NavItem>
  ) : (
    <NavItem>
      <div data-tracking-parent="edm-entry-global-nav">
        <button
          className="pe-0 btn-link nav-link text-white text-shadow-contrast"
          onClick={authenticatedSignUp}
          data-tracking-id="global_nav_begin_sign_up"
          title="My Account Sign-in"
          aria-label="My Account Sign-in"
        >
          <span className="icon-user3 insider-icon text-white" aria-hidden />
          <span className="text-white ms-0_5 icon-arrow-down3" aria-hidden />
        </button>
      </div>
    </NavItem>
  );

  const authorizedLinks = AUTHORIZED_LINKS.concat(isAnonymousUser ? [] : AUTHORIZATION_LINKS.account);
  const actionName = isAnonymousUser ? 'sign' : 'signOut';
  const actionMethod = isAnonymousUser ? toggleSignModal : doSignOut;

  const renderAuthorizedSection = isMobile ? (
    <Fragment>
      {authorizedLinks.map(link => renderNavItem(link, counts))}
      {renderNavItem(AUTHORIZATION_LINKS[actionName], {}, actionMethod)}
    </Fragment>
  ) : (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className={classnames('insider-dropdown dropdown-m', { 'pos-st': !dropdownOpen })}
    >
      <DropdownToggle
        tag="button"
        nav
        className="btn-link pe-0 ps-0_5 ps-md-1 text-shadow-contrast"
        title="My Account"
        aria-label="My Account"
      >
        <span className="icon-user3 insider-icon text-white" aria-hidden />
        <span className={classnames('legal text-white ms-0_5', dropdownOpen ? 'icon-arrow-up3' : 'icon-arrow-down3')} />
      </DropdownToggle>
      {dropdownOpen && (
        <DropdownMenu right className="insider-dropdown-menu">
          {authorizedLinks.map(link => (
            <DropdownItem
              tag={Link}
              key={link.text}
              role="menuitem"
              to={link.href}
              className={classnames('dropdown-item', DROPDOWN_ITEM_CLASSES)}
              data-tracking-id={link.trackingId}
              data-tracking-value={link.trackingValue}
              rel="nofollow"
              target={link.target}
            >
              {link.text}
              {link.countKey && !!counts[link.countKey] && <span className="ps-0_25">({counts[link.countKey]})</span>}
              {link.includeAlerts && <PriceDropAlerts className="pos-r" hideCount />}
            </DropdownItem>
          ))}
          <DropdownItem
            tag="button"
            role="menuitem"
            onClick={actionMethod}
            className={DROPDOWN_ITEM_CLASSES}
            data-tracking-id={AUTHORIZATION_LINKS[actionName].trackingId}
            data-tracking-value={AUTHORIZATION_LINKS[actionName].trackingValue}
          >
            {AUTHORIZATION_LINKS[actionName].text}
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );

  return (
    <Fragment>
      <Nav className={classnames('insider-section', className)}>
        {!isMobile && <PriceDropAlertsPopover showAlerts={isAuthenticated} isGlobalNavDesktop={isGlobalNavDesktop} />}
        {isAuthenticated ? renderAuthorizedSection : renderUnauthorizedSection}
      </Nav>
    </Fragment>
  );
}

InsiderSectionUI.propTypes = {
  isMobile: PropTypes.bool,
  toggleSignModal: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  signInAnonymousAndSaveProfile: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  updateLoginIdmData: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isAnonymousUser: PropTypes.bool,
  idmData: PropTypes.shape({}),
  disableGoogleOneTap: PropTypes.bool,
  isGlobalNavDesktop: PropTypes.bool,
  className: PropTypes.string,
  disableGoogleOneTapINP: PropTypes.bool,
  privacyAccepted: PropTypes.bool,
  isCore6746Enabled: PropTypes.bool,
  pageName: PropTypes.string,
};

InsiderSectionUI.defaultProps = {
  isMobile: false,
  isAuthenticated: false,
  isAnonymousUser: false,
  idmData: null,
  disableGoogleOneTap: false,
  isGlobalNavDesktop: false,
  className: null,
  disableGoogleOneTapINP: false,
  privacyAccepted: false,
  isCore6746Enabled: false,
  pageName: null,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  isAuthenticated: get(state, 'profile.isAuthenticated'),
  isAnonymousUser: get(state, 'profile.isAnonymousUser'),
  disableGoogleOneTap: !!get(state, "featureFlags['disable-google-one-tap']"),
  disableGoogleOneTapINP: !!get(state, "featureFlags['disable-google-one-tap-inp']"),
  pageName: get(state, 'pageContext.page.name'),
  isCore6746Enabled:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'core-6746-privacy4',
      defaultVal: 'ctrl',
    }) === 'chal',
});

const stateToPropsConfig = {
  idmData: bindToPath(props => (props.isAuthenticated ? 'data.idm' : ''), UserProfileModel),
  privacyAccepted: bindToPath('privacyAccepted', VisitorModel),
};

export const InsiderSection = connect(mapStateToProps)(
  connectToModel(profileScreenMethods(insiderMethods(connectedProfileAuth(InsiderSectionUI))), stateToPropsConfig)
);
