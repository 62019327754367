import React, { Component, createElement, StrictMode } from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { get, isFunction } from 'lodash';
import { ForceDefaultBanner } from 'site-modules/shared/components/experiment/force-default-banner';
import {
  pagePreload as pagePreloaded,
  pageStartLoading,
  pageLoad,
  pageUnload,
  pageStatusUpdate,
} from 'client/actions/page';
import { Redirect } from 'react-router-dom';
import { EventToolbox } from 'client/utils/event-toolbox';
import { logger } from 'client/utils/isomorphic-logger';
import { IS_NODE } from 'client/utils/environment';
import { HTTP_NOT_FOUND, HTTP_ERROR_500, HTTP_GONE, HTTP_BAD_REQUEST, HTTP_REDIRECT } from 'client/utils/http-status';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { ErrorPageMessage } from 'site-modules/shared/components/error-page-message/error-page-message';
import { NotFoundLayout } from 'site-modules/shared/components/not-found-layout/not-found-layout';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { EdmundsDefaultDecorator } from 'site-modules/shared/pages/decorators/edmunds-default';
import { PageSurveys } from 'site-modules/shared/components/page-surveys/page-surveys';
import { PrivacyGlobalDisclaimer } from 'site-modules/shared/components/privacy-global-disclaimer/privacy-global-disclaimer';
import { preloadSEO, updateSEO, DEFAULT_SEO_CONTEXT, NOT_FOUND_SEO_CONTEXT } from './seo';

const STORE_SHAPE = PropTypes.shape({
  getState: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
});

// TODO: Default for preload. We will probably have a real default behavior for this later on
function noop() {
  return Promise.resolve();
}

/**
 * Creates a Page component from the given options.
 *
 * @param {Object} pageOptions An object of the following format:
 *  {
 *    name: The display name of the page,
 *    component: A react component class/function containing the page contents,
 *    preload: Optional function that performs all necessary async preloading for server render, returns a Promise
 *  }
 *
 * @return A react component that renders the page.
 */
export function pageDefinition(pageOptions) {
  const {
    name,
    category,
    adsCustomTargeting,
    chunkName,
    component,
    options = {},
    gtmContainer,
    preloadAboveFold,
    preload: pagePreload = noop,
    seo: seoContext = DEFAULT_SEO_CONTEXT,
    decorator = EdmundsDefaultDecorator,
    isPageExperimentWrapper = false,
    isAppExtensionPage = false,
  } = pageOptions;

  class Page extends Component {
    static propTypes = {
      pageLoad: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
      pageUnload: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
      }).isRequired,
      store: STORE_SHAPE,
      route: PropTypes.shape({
        ignorePageEvents: PropTypes.bool,
      }),
      statusCode: PropTypes.number,
      redirectPath: PropTypes.string,
    };

    static defaultProps = {
      store: null,
      route: {
        ignorePageEvents: false,
      },
      statusCode: null,
      redirectPath: null,
    };

    static contextType = ReactReduxContext;
    /**
     * Handles page preload
     *
     * @param {Object} store - Redux Store
     * @param {Object} props - Preload Properties
     * @return {{isPreloadAboveFold: Boolean}} page preload data
     */
    static async preload(store, props) {
      const state = store.getState();
      let isPreloadAboveFold = false;
      if (get(state, 'pageContext.loading.isPreloaded', false)) {
        return { isPreloadAboveFold: false };
      }

      let preloadStatus;
      let pagePreloadResult = true;

      try {
        const preloads = [];
        if (decorator && isFunction(decorator.preload)) {
          preloads.push(decorator.preload(store, props));
        }

        let preloadToExecute = pagePreload;

        const isSearchBot = state?.request?.isSearchBot || state?.request?.isAkamaiCachePrefresh;
        if (!isSearchBot && preloadAboveFold) {
          preloadToExecute = preloadAboveFold;
          isPreloadAboveFold = true;
        }

        if (isFunction(preloadToExecute)) {
          preloads.push(preloadToExecute(store, props));
        }

        await Promise.all(preloads);

        return { isPreloadAboveFold };
      } catch (error) {
        pagePreloadResult = false;

        logger('error', error, error.message);

        if (
          (error.status === HTTP_NOT_FOUND && error.redirectPageTo404) ||
          error.status === HTTP_ERROR_500 ||
          error.status === HTTP_GONE ||
          error.status === HTTP_BAD_REQUEST
        ) {
          preloadStatus = error.status;
          if (IS_NODE) {
            throw error;
          } else {
            store.dispatch(pageStatusUpdate(error.status));
          }
        }

        return { isPreloadAboveFold };
      } finally {
        const seo =
          preloadStatus === HTTP_NOT_FOUND || preloadStatus === HTTP_GONE ? NOT_FOUND_SEO_CONTEXT : seoContext;
        const url = get(state, 'request.url');
        const fullUrl = `https://www.edmunds.com${url}`;
        const canonical = get(seo, 'canonical');
        const notFoundCanonical = get(NOT_FOUND_SEO_CONTEXT, 'canonical');
        const altMetadata = !!get(state, `featureFlags["enable-seot-3870-alt-metadata"]`);
        if (seo) {
          seo.canonical = canonical === notFoundCanonical ? fullUrl : canonical;
        }
        if (altMetadata && url === '/') {
          seo.title = 'Shop new and used cars online, and read expert car reviews | Edmunds';
          seo.description =
            'Shop for the perfect new or used car online, compare prices and incentives, research with car reviews and news, and explore rankings and buying guides.';
        }
        await preloadSEO(seo, store, props);
        await store.dispatch(updateSEO(seo, props));
        // preload result === false means some error occurred
        await store.dispatch(pagePreloaded(pagePreloadResult));

        const updatedState = store.getState();
        const isDisableDynamicRedirectsEnabled = get(updatedState, 'featureFlags.disableDynamicRedirects', false);

        if (!isDisableDynamicRedirectsEnabled) {
          // redirects on the server side are handled in server/routes.js.
          // the code in this block is only to handle client-side redirects.
          if (!IS_NODE && get(updatedState, 'redirect.path')) {
            store.dispatch(pageStatusUpdate(HTTP_REDIRECT));
          }
        }
      }
    }

    static getName() {
      return name;
    }

    static getCategory() {
      return category;
    }
    static getOptions() {
      return options;
    }

    static isPage() {
      return true;
    }

    static isAppExtensionPage() {
      return isAppExtensionPage;
    }

    static getComponent() {
      return component;
    }

    static getGtmContainer() {
      return gtmContainer;
    }

    static getAdsCustomTargeting() {
      return adsCustomTargeting;
    }

    static getChunkName() {
      return chunkName;
    }

    /**
     * Handles initial page render event
     *
     * @return {void}
     */
    componentDidMount() {
      this.onPageLoad(this.props);
    }

    shouldComponentUpdate(nextProps) {
      return this.isLocationChanged(nextProps);
    }

    /**
     * Handles subsequent page enter events
     *
     * @return {void}
     */
    componentDidUpdate(prevProps) {
      if (this.isLocationChanged(prevProps)) {
        this.onPageUnload(prevProps);
        this.onPageLoad(this.props);
      }
    }

    /**
     * Handles page unmount event
     *
     * @return {void}
     */
    componentWillUnmount() {
      this.onPageUnload(this.props);
    }

    /**
     * Calls pageLoad action on new page load
     *
     * @return {void}
     */
    onPageLoad = async props => {
      if (!isPageExperimentWrapper) {
        if (window.performance && window.performance.mark) {
          performance.mark('PAGE_START_LOADING');
        }
        EventToolbox.fireCustomEvent('PAGE_START_LOADING');
        const pageInfo = this.getPageInfo(props);
        const store = this.props.store || this.context.store;
        const state = store.getState();
        const previousLocation = get(state, 'pageContext.location', null);
        pageInfo.previousLocation = previousLocation && {
          ...previousLocation,
          // since hash is unavailable on server-side we'll use window.location.hash for first load
          // hash will be null only on first page load (see server/utils/request.js)
          hash: previousLocation.hash === null ? window.location.hash : previousLocation.hash,
        };
        props.pageStartLoading(pageInfo);
        await Page.preload(store, props);
        props.pageLoad(pageInfo);
      }
    };

    /**
     * Calls pageUnload action
     *
     * @return {void}
     */
    onPageUnload = props => {
      if (!isPageExperimentWrapper) {
        props.pageUnload(this.getPageInfo(props));
      }
    };

    /**
     * Returns page info
     *
     * @return {Object} Page info
     */
    getPageInfo = props => ({
      name,
      category,
      adsCustomTargeting,
      location: props.location,
      options,
    });

    /**
     *
     * @param {Object} props
     *
     * @return {boolean}
     */
    isLocationChanged = props => {
      if (this.props.route && this.props.route.ignorePageEvents && (props.route && props.route.ignorePageEvents)) {
        return false;
      }

      return (
        this.props.location.pathname !== props.location.pathname || this.props.location.search !== props.location.search
      );
    };

    renderPageComponent = () => {
      let pageComponent;
      switch (this.props.statusCode) {
        case HTTP_NOT_FOUND:
        case HTTP_GONE:
          pageComponent = NotFoundLayout;
          break;
        case HTTP_ERROR_500:
        case HTTP_BAD_REQUEST:
          pageComponent = ErrorPageMessage;
          break;
        default:
          pageComponent = component;
          break;
      }

      let pageContent;
      if (this.props.statusCode === HTTP_REDIRECT && this.props.redirectPath) {
        // redirects on the server side are handled in server/routes.js.
        // the code in this block is only to handle client-side redirects.
        pageContent = <Redirect to={this.props.redirectPath} />;
      } else {
        pageContent = createElement(pageComponent, { ...this.props });
      }

      return (
        <ErrorBoundary message={ErrorPageMessage}>
          <ForceDefaultBanner />
          {decorator
            ? createElement(
                decorator,
                {
                  ...this.props,
                },
                pageContent
              )
            : pageContent}
          <PageSurveys />
          <PrivacyGlobalDisclaimer />
        </ErrorBoundary>
      );
    };

    render() {
      return (
        <FeatureFlag name="strictMode">
          {enabled => (enabled ? <StrictMode>{this.renderPageComponent()}</StrictMode> : this.renderPageComponent())}
        </FeatureFlag>
      );
    }
  }

  const mapStateToProps = state => ({
    statusCode: state.pageContext.loading.statusCode,
    redirectPath: state.redirect && state.redirect.path,
    pageName: get(state, 'pageContext.page.name', ''),
  });
  const mapDispatchToProps = dispatch => ({
    pageStartLoading: page => dispatch(pageStartLoading(page)),
    pageLoad: page => dispatch(pageLoad(page)),
    pageUnload: page => dispatch(pageUnload(page)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page);
}
