import React, { useRef, useCallback, useEffect, useState, Fragment, startTransition, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input } from 'reactstrap';
import classnames from 'classnames';

import './autosized-area.scss';

export const AUTOSIZED_ID = 'autosized-area';

export const AutosizedArea = memo(
  ({
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    hintText,
    inputValue,
    className,
    inputMinHeight,
    autosizeOnFocus,
    disableOutline,
    innerInputRef,
    ...rest
  }) => {
    const ref = useRef();
    const inputRef = innerInputRef || ref;
    const [isCollapsed, setIsCollapsed] = useState(autosizeOnFocus);
    const [disabled, setDisabled] = useState(true);
    const [currentInputHeight, setCurrentInputHeight] = useState(inputMinHeight);

    useEffect(() => {
      setDisabled(false);
    }, []);

    useEffect(() => {
      if (autosizeOnFocus && isCollapsed) {
        return;
      }

      inputRef.current.style.setProperty('height', `${inputMinHeight}px`, 'important'); // this is for correct height when deleting a line
      const height = `${Math.max(inputMinHeight, inputRef.current.scrollHeight)}px`;
      inputRef.current.style.height = height;
      startTransition(() => {
        setCurrentInputHeight(height);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
      if (!autosizeOnFocus) {
        return;
      }

      if (isCollapsed) {
        setCurrentInputHeight(inputMinHeight);
      } else {
        const height = Math.max(inputMinHeight, inputRef.current.scrollHeight);
        setCurrentInputHeight(height);
      }
    }, [autosizeOnFocus, inputMinHeight, isCollapsed, inputRef]);

    const handleInputChange = useCallback(
      ({ target: { value } }) => {
        onChange(value);
      },
      [onChange]
    );

    const handleFocus = useCallback(
      event => {
        onFocus(event);
        if (autosizeOnFocus) {
          setIsCollapsed(false);
        }
      },
      [autosizeOnFocus, onFocus]
    );

    const handleBlur = useCallback(
      event => {
        onBlur(event);
        if (autosizeOnFocus) {
          setIsCollapsed(true);
        }
      },
      [autosizeOnFocus, onBlur]
    );

    const handleKeydown = useCallback(
      event => {
        onKeyDown(event);
      },
      [onKeyDown]
    );

    const style = useMemo(
      () => ({
        height: `${currentInputHeight}px`,
      }),
      [currentInputHeight]
    );

    return (
      <Fragment>
        <Input
          type="textarea"
          name="query"
          autoComplete="off"
          placeholder={hintText}
          className={classnames('autosized-area-field', className, {
            collapsed: isCollapsed,
            'with-shadow': autosizeOnFocus && currentInputHeight > inputMinHeight,
            'disable-outline': disableOutline,
          })}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeydown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          innerRef={inputRef}
          style={style}
          disabled={disabled}
          {...rest}
        />
      </Fragment>
    );
  }
);

AutosizedArea.displayName = 'AutosizedArea';

AutosizedArea.propTypes = {
  inputMinHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hintText: PropTypes.string,
  maxLength: PropTypes.number,
  id: PropTypes.string,
  inputValue: PropTypes.string,
  className: PropTypes.string,
  autosizeOnFocus: PropTypes.bool,
  disableOutline: PropTypes.bool,
  innerInputRef: PropTypes.shape({}),
};

AutosizedArea.defaultProps = {
  onChange: noop,
  onKeyDown: noop,
  onFocus: noop,
  onBlur: noop,
  hintText: '',
  maxLength: 200,
  id: AUTOSIZED_ID,
  inputValue: '',
  className: '',
  autosizeOnFocus: false,
  disableOutline: false,
  innerInputRef: undefined,
};
