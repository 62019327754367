import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { Link } from 'site-modules/shared/components/link/link';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import './privacy-global-disclaimer.scss';

function PrivacyGlobalDisclaimerUI({ privacyAccepted, setModelValue }) {
  function acceptPrivacy() {
    setModelValue('privacyAccepted', VisitorModel, 'true');
  }

  if (isUndefined(privacyAccepted)) {
    return null;
  }

  return (
    <Experiment name="core-6746-privacy4" showDefault>
      <Recipe name="ctrl" isDefault />
      <Recipe name="chal">
        {!privacyAccepted && (
          <div
            className="privacy-global-disclaimer justify-content-center mb-1 px-1 left-0 right-0 bottom-0"
            style={{ display: 'none' }}
          >
            <div className="privacy-content-wrapper">
              <div
                className="privacy-content pos-r rounded-8 bg-blue-100 pt-2_5 pt-md-1_75 pb-1_5 px-1_5"
                data-tracking-parent="privacy-disclaimer"
              >
                <h2 className="heading-4 mb-0_5">Your Privacy</h2>
                <div className="privacy-text medium text-cool-gray-10">
                  By accessing this website, you acknowledge that Edmunds and its third party business partners may use
                  cookies, pixels, and similar technologies to collect information about you and your interactions with
                  the website as described in our{' '}
                  <Link to="/about/privacy.html" className="text-underline">
                    Privacy Statement
                  </Link>
                  , and you agree that your use of the website is subject to our{' '}
                  <Link to="/about/visitor-agreement.html" className="text-underline">
                    Visitor Agreement
                  </Link>
                  .
                </div>
                <button
                  onClick={acceptPrivacy}
                  className="close-btn d-flex align-items-start justify-content-end text-cool-gray-10 size-20 bg-transparent border-0 rounded-8 pos-a top-0 left-0 right-0 pt-0_75 pe-1"
                  data-tracking-id="close_privacy_disclaimer"
                >
                  <span className="d-flex align-items-center">
                    Close <span className="visually-hidden">disclaimer</span>
                    <i className="icon-cross2 ms-0_25" aria-hidden />
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </Recipe>
    </Experiment>
  );
}

PrivacyGlobalDisclaimerUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  privacyAccepted: PropTypes.bool,
};

PrivacyGlobalDisclaimerUI.defaultProps = {
  privacyAccepted: undefined,
};

export const PrivacyGlobalDisclaimer = connectToModel(PrivacyGlobalDisclaimerUI, {
  privacyAccepted: bindToPath('privacyAccepted', VisitorModel),
});
