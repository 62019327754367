export const LINKS = [
  {
    href: 'https://help.edmunds.com/hc/en-us/requests/new',
    text: 'Contact Us',
  },
  {
    href: '/careers/',
    text: 'Careers',
  },
  {
    href: 'https://www.edmunds.com/about/privacy.html#adsonthirdpartysites',
    text: 'Your Ad Choices',
  },
  {
    href: 'https://www.edmunds.com/about/privacy.html',
    text: 'Privacy Statement',
  },
  {
    href: '/about/visitor-agreement.html',
    text: 'Visitor Agreement',
  },
  {
    href: '/about/accessibility.html',
    text: 'Accessibility',
  },
  {
    href: '/data-privacy-request/',
    text: 'Do Not Sell or Share My Personal Information',
  },
  {
    href: '/edmunds-info/',
    text: 'Edmunds Information',
  },
];

export const FOR_DEALERS_LINK = {
  href: 'https://dealers.edmunds.com/index',
  text: 'For Dealers',
};

export const LINKS_W_FOR_DEALERS = [...LINKS, FOR_DEALERS_LINK];
export const SOCIAL_LINKS = [
  {
    href: 'https://www.facebook.com/edmunds',
    title: 'Facebook',
    icon: 'icon-facebook2',
  },
  {
    href: 'https://www.pinterest.com/edmundsinc/',
    title: 'Pinterest',
    icon: 'icon-pinterest',
  },
  {
    href: 'https://www.youtube.com/user/Edmundsvideo',
    title: 'YouTube',
    icon: 'icon-play',
  },
  {
    href: 'https://x.com/edmunds',
    title: 'X',
    icon: 'icon-x',
  },
  {
    href: 'https://www.tiktok.com/@edmunds/',
    title: 'TikTok',
    icon: 'icon-tiktok',
  },
  {
    href: 'https://www.linkedin.com/company/edmunds-com',
    title: 'LinkedIn',
    icon: 'icon-linkedin',
  },
  {
    href: 'https://www.instagram.com/edmundscars/',
    title: 'Instagram',
    icon: 'icon-instagram',
  },
  {
    href: '//www.edmunds.com/rss.html',
    title: 'RSS Feed',
    icon: 'icon-rss',
  },
];

export const SOCIAL_LINKS_GLOBAL_FOOTER = [
  {
    href: 'https://www.youtube.com/user/Edmundsvideo',
    title: 'YouTube',
    icon: 'icon-play',
  },
  {
    href: 'https://www.instagram.com/edmundscars/',
    title: 'Instagram',
    icon: 'icon-instagram',
  },
  {
    href: 'https://www.tiktok.com/@edmunds/',
    title: 'TikTok',
    icon: 'icon-tiktok',
  },
  {
    href: 'https://www.linkedin.com/company/edmunds-com',
    title: 'LinkedIn',
    icon: 'icon-linkedin',
  },
  {
    href: 'https://www.facebook.com/edmunds',
    title: 'Facebook',
    icon: 'icon-facebook2',
  },
  {
    href: 'https://x.com/edmunds',
    title: 'X',
    icon: 'icon-x',
  },
  {
    href: '//www.edmunds.com/rss.html',
    title: 'RSS Feed',
    icon: 'icon-rss',
  },
];
