import React, { Component } from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { venomHistory } from 'client/utils/history/venom-history';
import fourOFour from 'client/images/404.jpg';

import './not-found-layout.scss';

export class NotFoundLayout extends Component {
  goBack = e => {
    e.preventDefault();
    venomHistory.goBack();
  };

  render() {
    return (
      <Container
        className="not-found-layout edm-entry error-container pb-1 pb-md-3"
        data-tracking-parent="edm-entry-error-container"
        data-test="not-found-layout-container"
      >
        <Row>
          <Col>
            <h2 className="p-1 p-md-3 text-center display-1">page not found</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="hidden-sm-down text-center">
            <img
              src={fourOFour}
              alt="Page Not Found"
              width={487}
              height={323}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Col>
          <Col md={6}>
            <p>
              You&apos;ve hit a roadblock! Unfortunately, what you&apos;re looking for isn&apos;t here, but we can still
              help you find what you need. Use the links below to detour to some of our most popular destinations.
            </p>
            <p>
              {/* todo - review PLAT-3859 disabling */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="" className="text-primary-darker" onClick={this.goBack} data-test="go-back-link">
                Go back to where you were
              </a>{' '}
              |{' '}
              <a href="/" className="text-primary-darker">
                Go to homepage
              </a>
            </p>
            <p>
              <a href="/finder/car-finder-results.html" className="text-primary-darker">
                Search for new cars
              </a>{' '}
              |{' '}
              <a href="/finder/car-finder-used.html" className="text-primary-darker">
                Search for used cars
              </a>{' '}
              |{' '}
              <a href="/car-reviews/" className="text-primary-darker">
                Read car reviews
              </a>
            </p>
            <p>
              <a href="/research/" className="text-primary-darker">
                Read tips on buying, leasing and selling
              </a>{' '}
              |{' '}
              <a href="//forums.edmunds.com/" className="text-primary-darker">
                Browse car forums
              </a>
            </p>
            <p>Or, use the search feature at the top of the page to find exactly what you&apos;re looking for.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}
