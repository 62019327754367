import { map, compact, get, orderBy } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { parseContent, extractMetadata, positionEntries } from 'client/data/cms/content';
import { CmsModel } from './cms';

function createAdArray(adsContent) {
  const adArray = map(adsContent, (value, key) => {
    const match = key.match(/cta(\d+)/);
    const adNumber = get(match, '[1]');

    if (!(adNumber && adsContent[`link${adNumber}`])) return undefined;

    return {
      cta: adsContent[`cta${adNumber}`],
      link: adsContent[`link${adNumber}`],
      text: adsContent[`text${adNumber}`],
    };
  });

  return compact(adArray);
}

function getMetadataValue(entry, key) {
  return entry && entry.hasMetadata && entry.hasMetadata(key) ? entry.metadata(key).value() : null;
}

function getMetadataBoolean(entry, key) {
  return entry && entry.hasMetadata && entry.hasMetadata(key) ? entry.metadata(key).boolean() : false;
}

function transformFeaturedSpotlightAds(contentArray) {
  const ads = [];

  contentArray.forEach(ad => {
    ads.push({
      logo: getMetadataValue(ad, 'logo'),
      logoText: getMetadataValue(ad, 'logoText'),
      headline: getMetadataValue(ad, 'headline'),
      url: getMetadataValue(ad, 'url'),
      urlText: getMetadataValue(ad, 'urlText'),
      urlMobile: getMetadataValue(ad, 'urlMobile'),
      oemLink: getMetadataValue(ad, 'oemLink'),
      urlTargetNew: getMetadataValue(ad, 'urlTargetNew'),
      image: getMetadataValue(ad, 'image'),
      contentLabel: getMetadataValue(ad, 'contentLabel'),
      adLabel: getMetadataValue(ad, 'adLabel'),
      disclaimer: getMetadataValue(ad, 'disclaimer'),
      gmAd: getMetadataValue(ad, 'gmAd'),
    });
  });
  return ads;
}

function transformPartnerPromotionAds(contentArray) {
  const ads = [];

  contentArray.forEach(ad => {
    ads.push({
      logo: getMetadataValue(ad, 'logo'),
      image: getMetadataValue(ad, 'image'),
      copy: getMetadataValue(ad, 'copy'),
      urlText: getMetadataValue(ad, 'urlText'),
      url: getMetadataValue(ad, 'url'),
      urlMobile: getMetadataValue(ad, 'urlMobile'),
      urlTargetNew: getMetadataBoolean(ad, 'urlTargetNew'),
      adLabel: getMetadataValue(ad, 'adLabel'),
      desktopHide: getMetadataBoolean(ad, 'desktopHide'),
      mobileHide: getMetadataBoolean(ad, 'mobileHide'),
    });
  });
  return ads;
}

export const AdsModel = createModelSegment('ads', [
  {
    path: 'featuredSpotlightAd',
    resolve(match, context) {
      return context
        .resolveValue('content["home-page-content"]', CmsModel)
        .then(content => {
          const featuredSpotlightContent = parseContent(content).child('featured-spotlight-carousel');
          const featuredSpotlightAds = transformFeaturedSpotlightAds(
            orderBy(
              positionEntries(featuredSpotlightContent.children()),
              slide => slide.metadata('adSlide').boolean(),
              ['desc']
            )
          );
          return featuredSpotlightAds;
        })
        .catch(() => null);
    },
  },
  {
    path: 'partnerPromotionsAd',
    resolve(match, context) {
      return context
        .resolveValue('content["home-page-content"]', CmsModel)
        .then(content => {
          const partnerPromotionsContent = parseContent(content).child('partner-promotions');
          const partnerPromotionTitle = getMetadataValue(partnerPromotionsContent, 'title');
          const partnerPromotionsContentArray = partnerPromotionsContent ? partnerPromotionsContent.children() : [];
          const partnerPromotionsAds = transformPartnerPromotionAds(partnerPromotionsContentArray);

          return { title: partnerPromotionTitle, partnerPromoAds: partnerPromotionsAds };
        })
        .catch(() => null);
    },
  },
  {
    path: 'heroOverlay.makes["{makeSlug}"].models["{modelSlug}"].years["{year}"]',
    resolve(match, context) {
      const { makeSlug, modelSlug, year } = match;
      return context.resolveValue(`content["/${makeSlug}/${modelSlug}/ads"]`, CmsModel).then(content => {
        const overlayContent = parseContent(content);
        const NEW = overlayContent
          .metadata('newYears')
          .value()
          .includes(year);
        const USED = overlayContent
          .metadata('usedYears')
          .value()
          .includes(year);

        if (!(NEW || USED)) return {};

        const [wiredEntryName, mobileEntryName] = NEW
          ? ['wired-overlay', 'mobile-overlay']
          : ['wired-overlay-used', 'mobile-overlay-used'];
        const wiredData = createAdArray(
          extractMetadata(['link1', 'cta1', 'text1'], overlayContent.child(wiredEntryName))
        );
        const mobileData = createAdArray(
          extractMetadata(['link1', 'cta1', 'text1'], overlayContent.child(mobileEntryName))
        );
        return {
          wiredData,
          mobileData,
        };
      });
    },
  },
  {
    path: 'nativeAdActiveMakes.{makesList}',
    resolve(match, context) {
      const { makesList } = match;
      return context.resolveValue('content["/ads/native/native-ads-makes"]', CmsModel).then(content => {
        const overlayContent = parseContent(content).child('native-ads-makes');
        if (overlayContent.hasMetadata(makesList)) {
          const activeMakesList = overlayContent.metadata(makesList).json([]);
          return activeMakesList;
        }
        return [];
      });
    },
  },
  {
    path: 'adhesionBanner',
    update(value) {
      return Promise.resolve(value);
    },
  },
]);
