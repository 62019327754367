import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { Button } from 'site-modules/shared/components/inventory/button/button';
import { EpoLoadingIndicator } from 'site-modules/shared/components/appraisal/trade-in-appraiser-steps/epo-processing/epo-loading-indicator/epo-loading-indicator';
import { FocusScope } from '@react-aria/focus';

import './personalized-search-loading-screen.scss';

const PROCESS_STEP_COPY = ['Reviewing your preferences', 'Searching thousands of cars', 'Loading results'];
const PROCESS_STEPS = {
  start: -1,
  reviewing: 0,
  searching: 1,
  completed: 2,
};
const PROCESS_TIMES = [1500, 1500, 500];
export const LOADING_SCREEN_CREATIVE_ID = 'loading-state-semantic-inventory-search';

function fireWidgetViewTracking() {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      total_page_height: Math.max(document.body.scrollHeight, document.body.offsetHeight),
      creative_id: LOADING_SCREEN_CREATIVE_ID,
    },
  });
}

export function PersonalizeSearchLoadingScreen({
  isLoading: isLoadingProp,
  onCancel,
  onAbortLoading,
  isCancelDisabled,
  preserveLoadingScreen,
}) {
  const [isLoading, setIsLoading] = useState(isLoadingProp);
  const [loadingStepNumber, setLoadingStepNumber] = useState(PROCESS_STEPS.start);
  const nextStepTimeoutId = useRef(null);
  const divRef = useRef();

  const clearTimeouts = useCallback(() => {
    if (nextStepTimeoutId.current) {
      clearTimeout(nextStepTimeoutId.current);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      divRef.current?.focus();
    }
  }, [isLoading]);

  const forceTimeouts = useCallback(() => {
    clearTimeouts();
    setLoadingStepNumber(PROCESS_STEPS.completed);
  }, [clearTimeouts]);

  const fireCancelTracking = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_RESET_NAVIGATION,
        subaction_name: TrackingConstant.CLOSE_DRAWER,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: LOADING_SCREEN_CREATIVE_ID,
        value: 'cancel',
      },
    });
  }, []);

  useEffect(() => {
    if (isLoadingProp) {
      setIsLoading(isLoadingProp);
      setLoadingStepNumber(PROCESS_STEPS.start);
    }
    if (!isLoadingProp && isLoading) {
      forceTimeouts();
    }
    // eslint-disable-next-line
  }, [forceTimeouts, isLoadingProp]);

  useEffect(() => {
    if (isLoading) {
      if (loadingStepNumber < PROCESS_STEPS.searching) {
        nextStepTimeoutId.current = setTimeout(() => {
          setLoadingStepNumber(loadingStepNumber + 1);
        }, PROCESS_TIMES[loadingStepNumber + 1]);
      }

      if (loadingStepNumber === PROCESS_STEPS.completed) {
        setTimeout(() => {
          setIsLoading(false);
        }, PROCESS_TIMES[PROCESS_STEPS.completed]);
      }
    }

    return () => {
      clearTimeouts();
    };
  }, [clearTimeouts, isLoading, loadingStepNumber]);

  useEffect(() => {
    if (loadingStepNumber === PROCESS_STEPS.reviewing) {
      fireWidgetViewTracking();
    }
  }, [loadingStepNumber]);

  const handleCancel = useCallback(() => {
    onAbortLoading();
    onCancel();
    fireCancelTracking();
  }, [onAbortLoading, onCancel, fireCancelTracking]);

  if (!isLoading && !preserveLoadingScreen) {
    return null;
  }

  return (
    <FocusScope contain restoreFocus>
      <div className="personalized-search-loading-screen text-center" role="region" tabIndex={-1} ref={divRef}>
        <div className="heading-2 text-center mb-1_5">
          Revving up your <br />
          <i className="text-blue-50">perfect</i> matches
        </div>
        <div className="mx-auto text-start size-16 d-flex justify-content-center align-items-center">
          <div>
            <EpoLoadingIndicator isSubmittingFinished={loadingStepNumber === PROCESS_STEPS.completed} />
            <ul className="list-unstyled mt-1_5" aria-label="Loading steps">
              {PROCESS_STEP_COPY.map((copy, index) => {
                const isStepDone = index <= loadingStepNumber;
                return (
                  <Fragment key={copy}>
                    <li className={classnames('mb-1 d-flex', { 'opacity-5': !isStepDone })}>
                      {isStepDone ? (
                        <i aria-hidden className="icon icon-checkmark-circle size-20 text-green-50 me-1" />
                      ) : (
                        <div className="spinner-container">
                          <Spinner className="me-1" size={20} thickness={2} color="green-50" />
                        </div>
                      )}
                      <div
                        className={classnames({
                          'fw-medium text-gray-darker': isStepDone,
                          'fw-normal text-cool-gray-40': !isStepDone,
                        })}
                      >
                        {copy}
                      </div>
                    </li>
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </div>
        <Button
          className="cancel-btn text-cool-gray-30 text-transform-none size-16 fw-medium mt-0_5 py-0_5 px-1"
          onClick={handleCancel}
          disabled={isCancelDisabled}
          aria-label="Cancel loading"
        >
          Cancel
        </Button>
      </div>
    </FocusScope>
  );
}
PersonalizeSearchLoadingScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onAbortLoading: PropTypes.func,
  isCancelDisabled: PropTypes.bool,
  preserveLoadingScreen: PropTypes.bool,
};

PersonalizeSearchLoadingScreen.defaultProps = {
  onCancel: noop,
  onAbortLoading: noop,
  isCancelDisabled: false,
  preserveLoadingScreen: false,
};
